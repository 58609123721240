// types
import type { PublicRuntimeConfig } from '@nuxt/schema'

// nuxt
import { createError } from '#app'

// utilities
import { errorsToSentence } from '@revolutionprep/utils'

export function createExamsApiFetchInstance<T> (config: PublicRuntimeConfig) {
  return $fetch.create<T>({
    baseURL: String(config.examsUrl),
    headers: {
      Accept: 'application/vnd.api+json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    onResponseError: ({ response }) => {
      if (!response.ok) {
        throw createError({
          statusCode: response.status,
          statusMessage:
            response._data.error ||
            errorsToSentence(response._data.errors, 'orbit')
        })
      }
    }
  })
}
