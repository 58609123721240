// types
import type {
  HeadsUpNotice,
  Notice
} from '@revolutionprep/types'

// nuxt
import { useNuxtApp } from '#app'

// logger
import consolaLogger from 'consola'

export function usePusher () {
  /**
   * runtime config
   * ==================================================================
   */
  const config = useRuntimeConfig()

  /**
   * nuxt app
   * ==================================================================
   */
  const { $pusher } = useNuxtApp()

  /**
   * logger
   * ==================================================================
   */
  const logger = consolaLogger.withTag('Pusher')

  /**
   * stores
   * ==================================================================
   */
  const noticeStore = useNoticeStore()
  const {
    headsUpNotice,
    newNotice,
    showHeadsUpNoticeSnackbar,
    showNewNoticeSnackbar
  } = storeToRefs(noticeStore)

  /**
   * computed
   * ==================================================================
   */
  const isDev = computed(() => {
    return config.public.appStage !== 'production'
  })

  /**
   * methods
   * ==================================================================
   */
  function initializePusher () {
    if (process.client) {
      $pusher.init()
    }
  }

  function subscribeToGlobalChannels (userId: number) {
    const privateChannelName = `private-revolution-user-${userId}`

    $pusher.bindEvent(
      'new-notice',
      privateChannelName,
      (data: { notice: Notice }) => {
        if (isDev) {
          logger.info(privateChannelName, 'new-notice', data)
        }
        newNotice.value = data.notice
        showNewNoticeSnackbar.value = true
      }
    )

    $pusher.bindEvent(
      'heads-up',
      privateChannelName,
      (data: HeadsUpNotice) => {
        if (isDev) {
          logger.info(privateChannelName, 'heads-up', data)
        }
        headsUpNotice.value = data
        showHeadsUpNoticeSnackbar.value = true
      }
    )
  }

  function unsubscribeFromGlobalChannels (userId: number) {
    const privateChannelName = `private-revolution-user-${userId}`

    $pusher.unbindEvent('new-notice', privateChannelName)

    $pusher.unbindEvent('heads-up', privateChannelName)

    $pusher.unsubscribeChannel(privateChannelName)
  }

  return {
    initializePusher,
    subscribeToGlobalChannels,
    unsubscribeFromGlobalChannels
  }
}
