<script setup lang="ts" />

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M10 5.83594V17.5026"
        stroke="#5E5E5E"
        stroke-width="1.04167"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M13.3359 10H15.0026"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M13.3359 6.66406H15.0026"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M2.4974 15C2.27638 15 2.06442 14.9122 1.90814 14.7559C1.75186 14.5996 1.66406 14.3877 1.66406 14.1667V3.33333C1.66406 3.11232 1.75186 2.90036 1.90814 2.74408C2.06442 2.5878 2.27638 2.5 2.4974 2.5H6.66406C7.54812 2.5 8.39596 2.85119 9.02109 3.47631C9.64621 4.10143 9.9974 4.94928 9.9974 5.83333C9.9974 4.94928 10.3486 4.10143 10.9737 3.47631C11.5988 2.85119 12.4467 2.5 13.3307 2.5H17.4974C17.7184 2.5 17.9304 2.5878 18.0867 2.74408C18.2429 2.90036 18.3307 3.11232 18.3307 3.33333V14.1667C18.3307 14.3877 18.2429 14.5996 18.0867 14.7559C17.9304 14.9122 17.7184 15 17.4974 15H12.4974C11.8344 15 11.1985 15.2634 10.7296 15.7322C10.2608 16.2011 9.9974 16.837 9.9974 17.5C9.9974 16.837 9.734 16.2011 9.26516 15.7322C8.79632 15.2634 8.16044 15 7.4974 15H2.4974Z"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M5 10H6.66667"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M5 6.66406H6.66667"
        stroke="#5E5E5E"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
