<script setup lang="ts" />

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66699 1.66699V5.00033"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.333 1.66699V5.00033"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.5 11.6663V4.99967C17.5 4.55765 17.3244 4.13372 17.0118 3.82116C16.6993 3.5086 16.2754 3.33301 15.8333 3.33301H4.16667C3.72464 3.33301 3.30072 3.5086 2.98816 3.82116C2.67559 4.13372 2.5 4.55765 2.5 4.99967V16.6663C2.5 17.1084 2.67559 17.5323 2.98816 17.8449C3.30072 18.1574 3.72464 18.333 4.16667 18.333H10.8333"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.5 8.33301H17.5"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.333 16.6667L14.9997 18.3333L18.333 15"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
