import type { NitroFetchOptions, NitroFetchRequest, $Fetch } from 'nitropack'

export const apiFetchRepository = (
  fetch: $Fetch<unknown, NitroFetchRequest>,
  resource: string
) => ({
  async create<T> (
    payload: Record<string, any> | undefined,
    config: NitroFetchOptions<string> = {}
  ): Promise<T> {
    return await fetch<T>(resource, {
      ...config,
      body: payload,
      method: 'POST'
    })
  },
  async delete<T> (
    id: number | string,
    payload: Record<string, any> | undefined,
    config: NitroFetchOptions<string> = {}
  ): Promise<T> {
    return await fetch<T>(
        `${resource}/${id}`,
        {
          ...config,
          body: payload,
          method: 'DELETE'
        }
    )
  },
  async index<T> (
    config: NitroFetchOptions<string> = {}
  ): Promise<T> {
    return await fetch<T>(
      resource,
      {
        ...config,
        method: 'GET'
      }
    )
  },
  async show<T> (
    id: number | string,
    config: NitroFetchOptions<string> = {}
  ): Promise<T> {
    return await fetch<T>(
        `${resource}/${id}`,
        {
          ...config,
          method: 'GET'
        }
    )
  },
  async update<T> (
    id: number | string,
    payload: Record<string, unknown> | undefined,
    config: NitroFetchOptions<string> = {}
  ): Promise<T> {
    if (config.method !== 'PATCH') {
      return await fetch<T>(
          `${resource}/${id}`,
          {
            ...config,
            body: payload,
            method: 'PUT'
          }
      )
    }
    return fetch<T>(
        `${resource}/${id}`,
        {
          ...config,
          body: payload,
          method: 'PATCH'
        }
    )
  }
})
