<script setup lang="ts" />

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.625 2.5V4.375M14.375 2.5V4.375M2.5 15.625V6.25C2.5 5.21447 3.33947 4.375 4.375 4.375H15.625C16.6605 4.375 17.5 5.21447 17.5 6.25V15.625M2.5 15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625M2.5 15.625V9.375C2.5 8.33947 3.33947 7.5 4.375 7.5H15.625C16.6605 7.5 17.5 8.33947 17.5 9.375V15.625M10 10.625H10.0063V10.6313H10V10.625ZM10 12.5H10.0063V12.5063H10V12.5ZM10 14.375H10.0063V14.3813H10V14.375ZM8.125 12.5H8.13125V12.5063H8.125V12.5ZM8.125 14.375H8.13125V14.3813H8.125V14.375ZM6.25 12.5H6.25625V12.5063H6.25V12.5ZM6.25 14.375H6.25625V14.3813H6.25V14.375ZM11.875 10.625H11.8813V10.6313H11.875V10.625ZM11.875 12.5H11.8813V12.5063H11.875V12.5ZM11.875 14.375H11.8813V14.3813H11.875V14.375ZM13.75 10.625H13.7563V10.6313H13.75V10.625ZM13.75 12.5H13.7563V12.5063H13.75V12.5Z"
      stroke="#121212"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
