import { GrowthBook } from '@growthbook/growthbook'
export default defineNuxtPlugin(async () => {
  /**
 * nuxt runtime config
 * ==================================================================
 */
  const config = useRuntimeConfig()

  const apiHost = config.public.growthbookApiHost
  const clientKey = config.public.growthbookApiKey

  const gbInstance = reactive(
    new GrowthBook({
      apiHost,
      clientKey
    })
  )

  const initializeGrowthBook = async () => {
    try {
      await gbInstance.init({ streaming: true })
      return gbInstance
    } catch (e) {
      return null
    }
  }

  await initializeGrowthBook()

  return {
    provide: {
      growthbook: gbInstance
    }
  }
})
