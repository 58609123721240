// nuxt
import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useNuxtApp
} from '#app'

// types
import type { ActorType } from '@revolutionprep/types'

export default defineNuxtRouteMiddleware((to) => {
  const { $actor } = useNuxtApp()

  const spoofingQuery = to.query.spoofing
  if (spoofingQuery && spoofingQuery !== 'undefined') {
    const spoofee = String(to.query.spoofing).split('-')
    $actor.core.spoof({
      id: Number(spoofee[1]),
      type: spoofee[0] as ActorType
    })
  }

  const isLoggedIn = $actor.core.storage.getUniversal('isLoggedIn')

  if (to.path === '/login' && isLoggedIn) {
    return navigateTo('/')
  }
})
