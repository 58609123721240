<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g
      transform="translate(8 20) scale(0.5)"
    >
      <g
        id="Eyes/Cry-😢"
        transform="translate(0.000000, 8.000000)"
      >
        <circle
          class="CustomColor"
          fill="#000000"
          fillRule="evenodd"
          cx="30"
          cy="22"
          r="6"
        />
        <path
          id="Drop"
          d="M25,27 C25,27 19,34.2706667 19,38.2706667 C19,41.5846667 21.686,44.2706667 25,44.2706667 C28.314,44.2706667 31,41.5846667 31,38.2706667 C31,34.2706667 25,27 25,27 Z"
          fill="#92D9FF"
          fillRule="nonzero"
        />
        <circle
          class="CustomColor"
          fill="#000000"
          fillRule="evenodd"
          cx="82"
          cy="22"
          r="6"
        />
      </g>
    </g>
  </svg>
</template>
