<script setup lang="ts" />

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.3329 1.66406V7.93656C8.33314 8.19574 8.27294 8.4514 8.15707 8.68323L3.9329 17.1224C3.86866 17.2499 3.83824 17.3917 3.84457 17.5344C3.8509 17.677 3.89376 17.8156 3.96905 17.9369C4.04434 18.0582 4.14952 18.1581 4.27452 18.2271C4.39952 18.296 4.54014 18.3317 4.6829 18.3307H15.3162C15.459 18.3317 15.5996 18.296 15.7246 18.2271C15.8496 18.1581 15.9548 18.0582 16.0301 17.9369C16.1054 17.8156 16.1482 17.677 16.1546 17.5344C16.1609 17.3917 16.1305 17.2499 16.0662 17.1224L11.8421 8.68323C11.7262 8.4514 11.666 8.19574 11.6662 7.93656V1.66406"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.08594 1.66406H12.9193"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.83594 13.3359H14.1693"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
