<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g id="Top">
      <g
        id="Layer_2"
        transform="translate(15 18) scale(0.3)"
        data-name="Layer 2"
      >
        <g
          id="Layer_1-2"
          data-name="Layer 1"
        >
          <path
            fill="var(--avataaar-hat-color)"
            d="M137,20A20,20,0,0,0,99.89,9.68a65.94,65.94,0,0,0-61-.39,20,20,0,1,0-28,27.35A65.79,65.79,0,0,0,3,68v5H135V68a65.59,65.59,0,0,0-7.68-30.89A20,20,0,0,0,137,20Z"
          />
          <g opacity="0.25">
            <path d="M127.32,37.11A20,20,0,1,0,99.82,9.79,66.37,66.37,0,0,1,127.32,37.11Z" />
            <path d="M39,9.48A20,20,0,1,0,11.06,36.73,66.28,66.28,0,0,1,39,9.48Z" />
          </g>
          <g opacity="0.25">
            <path
              fill="#fff"
              d="M123.12,30.31a12,12,0,1,0-16.47-16.37A66.37,66.37,0,0,1,123.12,30.31Z"
            />
            <path
              fill="#fff"
              d="M32.1,13.54A12,12,0,1,0,15.32,30,66.17,66.17,0,0,1,32.1,13.54Z"
            />
          </g>
          <path
            id="hat-front-2"
            fill="#f4f4f4"
            d="M0,72a8,8,0,0,1,5.78-7.68S37.46,46,69.07,46s63.16,18.33,63.16,18.33A8,8,0,0,1,138,72v30.65a4,4,0,0,1-4,4,4.34,4.34,0,0,1-1.13-.16S101.75,88,69.8,88,5.1,106.54,5.1,106.54a4,4,0,0,1-4.95-2.75,4.11,4.11,0,0,1-.15-1.1Z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
