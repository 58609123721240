// types
import type { PublicRuntimeConfig } from '@nuxt/schema'

// nuxt
import {
  createError,
  useNuxtApp,
  useRequestHeaders
} from '#app'

// utilities
import { errorsToSentence } from '@revolutionprep/utils'

export function createOrbitApiFetchInstance<T> (config: PublicRuntimeConfig) {
  return $fetch.create<T>({
    baseURL: String(config.orbitApiBaseUrl),
    credentials: 'include',
    onRequest: ({ options }) => {
      const { $actor } = useNuxtApp()

      const headers = useRequestHeaders(['cookie'])
      options.headers = {
        ...headers,
        Accept: 'application/vnd.api+json'
      }

      // set X-TIMEZONE header to the user's browser timezone
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (timeZone) {
        options.headers['X-TIMEZONE'] = timeZone
      }

      // if spoofing is happening add X-SPOOFING header
      if (
        !options.params?.isAdminRequest &&
        $actor.core.storage.dynamicState.value.isLoggedIn &&
        $actor.core.storage.getUniversal('spoofing')
      ) {
        options.headers['X-SPOOFING'] =
          $actor.core.storage.getUniversal('spoofing') as string
      }
    },
    onResponseError: async ({ response }) => {
      const { $actor } = useNuxtApp()

      if (!response.ok) {
        if ([400, 401, 403].includes(response.status)) {
          await $actor.core.doLogout()
        }
        throw createError({
          statusCode: response.status,
          statusMessage:
            response._data.error ||
            errorsToSentence(response._data.errors, 'orbit')
        })
      }
    }
  })
}
