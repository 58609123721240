// types
import type { Nullable, Enrollment } from '@revolutionprep/types'

export const useTrialStore = defineStore('trial', () => {
  // state
  const activeTrialDays = ref(0)
  const isActiveTrial = ref(false)
  const isExpiredTrial = ref(false)
  const trialEnrollment = ref<Nullable<Enrollment>>(null)

  return {
    activeTrialDays,
    isActiveTrial,
    isExpiredTrial,
    trialEnrollment
  }
})
