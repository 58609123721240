import type { ErrorHash } from '@revolutionprep/types'
import toSentence from './toSentence'
import capitalizeFirst from './capitalizeFirst'

/**
 * Utility to turn error object into a Array of sentences.
 * Handles both orbit and vee-validate error hashes.
 *
 * @param {ErrorHash} hash
 * @param {type} string
 * @returns {string[]}
 */
export default function (hash: ErrorHash | Partial<Record<string, string>>, type: string): string[] {
  const errors: string[] = []
  Object.entries(hash).forEach(([key, value]) => {
    key = capitalizeFirst(key)
    key = key.replace(/_/g, ' ').replace(/\./g, ' ')
    if (Array.isArray(value)) {
      value = toSentence(value)
    }
    if ((value as string).length) {
      if (type === 'orbit') {
        errors.push(`${key} ${value}`.replace('Base ', ''))
      } else if (type === 'vee-validate') {
        errors.push(capitalizeFirst(value as string))
      }
    }
  })
  return errors
}
