<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Layer_2"
      transform="scale(0.28)"
      data-name="Layer 2"
    >
      <g
        id="Layer_1-2"
        data-name="Layer 1"
      >
        <path d="M240,118.86A120,120,0,1,0,36.85,206.51a71.93,71.93,0,0,1,55.71-27.25l4,0-.17-18.39A56,56,0,0,1,64.16,115.4a12,12,0,0,1-10.32-11.77l-.12-13A12,12,0,0,1,63.61,78.7l-.06-6.17a56,56,0,1,1,112-1.06l.06,6.17a12,12,0,0,1,10.11,11.73l.13,13a12,12,0,0,1-10.1,12,55.92,55.92,0,0,1-31.36,46l.18,18.39,4,0a71.79,71.79,0,0,1,56.22,26.19A119.65,119.65,0,0,0,240,118.86Z" />
      </g>
    </g>
  </svg>
</template>
