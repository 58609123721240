// types
import type { ModuleOptions } from '@nuxt/schema'

// nuxt
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

// pusher
import { usePusher } from './core/pusher'

export default defineNuxtPlugin({
  name: '@revolutionprep/pusher',
  setup () {
    // runtime config
    const publicRuntimeConfig = useRuntimeConfig().public
    const pusherRuntimeConfig = publicRuntimeConfig.pusher as ModuleOptions

    return {
      provide: {
        pusher: usePusher(pusherRuntimeConfig)
      }
    }
  }
})
