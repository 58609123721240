// vue
import { computed, watch } from 'vue'

// types
import type { ModuleOptions } from '@nuxt/schema'
import type { WatchOptions } from 'vue'

// pinia
import { getActivePinia, storeToRefs } from 'pinia'

// composables
import { useErrorHandler } from '../composables'

// utilities
import { useActorStore } from '../store/actor'
import { isUnset, isSet, decodeValue, encodeValue } from './utilities'

// nuxt
import type { CookieOptions } from '#app'
import { useCookie } from '#app'

export function useActorStorage (options: ModuleOptions) {
  /**
   * error handler
   * ==================================================================
   */
  const { doHandleError } = useErrorHandler()

  /**
   * pinia + stores
   * ==================================================================
   */
  const _isPiniaActive = computed(() => {
    return getActivePinia()
  })
  const _actorStore = useActorStore()
  const { dynamicState } = storeToRefs(_actorStore)

  /**
   * universal state
   * ==================================================================
   */
  const setUniversal = (key: string, value: unknown) => {
    if (isUnset(value)) {
      return removeUniversal(key)
    }

    // Cookies
    setCookie(key, value)

    // Local storage
    setLocalStorage(key, value)

    // Local state
    setState(key, value)

    return value
  }

  const getUniversal = <T>(key: string) => {
    let value

    // Local state
    if (process.server) {
      value = getState(key)
    }

    // Cookies
    if (isUnset(value)) {
      value = getCookie(key)?.value
    }

    // Local storage
    if (isUnset(value)) {
      value = getLocalStorage(key)
    }

    // Local state
    if (isUnset(value)) {
      value = getState(key)
    }

    return value as T
  }

  const syncUniversal = (key: string, defaultValue?: unknown) => {
    let value = getUniversal(key)

    if (isUnset(value) && isSet(defaultValue)) {
      value = defaultValue
    }

    if (isSet(value)) {
      setUniversal(key, value)
    }

    return value
  }

  const removeUniversal = (key: string) => {
    removeState(key)
    removeLocalStorage(key)
    removeCookie(key)
  }

  /**
   * local state (pinia)
   * ==================================================================
   */
  function _initState () {
    if (_isPiniaActive.value) {
      // Setup initial state
      for (const state in options.initialState) {
        dynamicState.value[state] = options.initialState[state]
      }
    } else {
      // eslint-disable-next-line
      console.warn('[Actor] Pinia is not activated')
    }

    // Restore universal state
    const universalState = options.universalState
    for (const key in universalState) {
      syncUniversal(key, universalState[key])
    }
  }

  function setState (key: string, value: unknown) {
    if (!_isPiniaActive.value) {
      return
    }
    dynamicState.value = {
      ...dynamicState.value,
      [key]: value
    }
    return value
  }

  function getState<T> (key: string) {
    return dynamicState.value[key] as T
  }

  function watchState (
    key: string,
    handler: (...args: any) => void,
    options?: WatchOptions
  ) {
    watch(() => dynamicState.value[key], handler, options)
  }

  function removeState (key: string) {
    setState(key, undefined)
  }

  /**
   * local storage
   * ==================================================================
   */
  const _isLocalStorageAvailable = computed(() => {
    return (
      (
        process.client ||
        !(typeof localStorage === 'undefined') ||
        _isLocalStorageEnabled()
      ) &&
      options.localStorage
    )
  })

  function setLocalStorage (key: string, value: unknown) {
    if (!_isLocalStorageAvailable.value) {
      return
    }
    // add specified prefix to key name
    const _key = options.localStorage.prefix + key

    if (isUnset(value)) {
      removeLocalStorage(_key)
    }

    try {
      localStorage.setItem(_key, encodeValue(value))
    } catch (error) {
      if (!options.ignoreExceptions) {
        doHandleError(error as Error)
      }
    }

    return value
  }

  function getLocalStorage (key: string) {
    if (!_isLocalStorageAvailable.value) {
      return
    }

    const _key = options.localStorage.prefix + key
    const value = localStorage.getItem(_key)

    return decodeValue(value)
  }

  function removeLocalStorage (key: string) {
    if (!_isLocalStorageAvailable.value) {
      return
    }
    const _key = options.localStorage.prefix + key
    localStorage.removeItem(_key)
  }

  function _isLocalStorageEnabled () {
    // There's no great way to check if localStorage is enabled; most solutions
    // error out. So have to use this hacky approach
    // https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
    if (!process.client) {
      return
    }
    const test = 'test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (error) {
      if (!options.ignoreExceptions) {
        doHandleError(error as Error)
      }
      if (options.localStorage && process.client) {
        // eslint-disable-next-line no-console
        console.warn(
          "[Actor] Local storage is enabled in config, but browser doesn't support it"
        )
      }
      return false
    }
  }

  /**
   * cookies
   * ==================================================================
   */
  function setCookie (
    key: string,
    value: unknown,
    cookieOptions?: CookieOptions
  ) {
    if (!options.cookie) {
      return
    }
    const _key = options.cookie.prefix + key
    const _options = Object.assign({}, options.cookie.options, cookieOptions)
    const _value = encodeValue(value)

    if (isUnset(value)) {
      _options.maxAge = -1
    }

    // Accept expires as a number for js-cookie compatibility
    if (typeof _options.expires === 'number') {
      _options.expires = new Date(Date.now() + _options.expires * 864e5)
    }

    const setCookie = useCookie(_key, _options)
    setCookie.value = _value
    return value
  }

  function getCookie (key: string) {
    if (!options.cookie) {
      return
    }
    const _key = options.cookie.prefix + key
    return useCookie(_key)
  }

  function removeCookie (key: string) {
    if (!options.cookie) {
      return
    }
    const removeCookie = getCookie(key)
    if (removeCookie?.value) {
      removeCookie.value = undefined
    }
  }

  /**
   * initialize storage
   * ==================================================================
   */
  _initState()

  return {
    dynamicState,
    getCookie,
    getLocalStorage,
    getState,
    getUniversal,
    removeCookie,
    removeLocalStorage,
    removeState,
    removeUniversal,
    setCookie,
    setLocalStorage,
    setUniversal,
    setState,
    syncUniversal,
    watchState
  }
}
