<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Clothing/Shirt-V-Neck"
      transform="translate(-24 21) scale(0.43)"
    >
      <defs>
        <path
          id="ShirtVNeck-path1"
          d="M171.319631,29.9364358 C205.706337,35.3665707 232,65.13854 232,101.051724 L232,110 L32,110 L32,101.051724 C32,65.1380521 58.2943778,35.3657617 92.6817711,29.9362145 C93.5835973,35.0053598 96.116393,39.8238432 100.236125,43.5389794 L100.236125,43.5389794 L129.321203,69.7676333 C130.843316,71.1402598 133.156684,71.1402598 134.678797,69.7676333 L134.678797,69.7676333 L163.763875,43.5389794 C164.189462,43.1551884 164.601167,42.7562772 164.998197,42.3430127 C168.414164,38.7873666 170.517305,34.4520434 171.319628,29.9364354 Z"
        />
      </defs>
      <mask
        id="ShirtVNeck-mask1"
        fill="white"
      >
        <use href="#ShirtVNeck-path1" />
      </mask>
      <use
        class="CustomColor"
        fill="var(--avataaar-shirt-color)"
        href="#ShirtVNeck-path1"
      />
    </g>

  </svg>
</template>

<style scoped>
.circle {
  width: 64px;
  height: 64px;
  border-radius:  100%;
  overflow: hidden;
  margin: 0 auto;/* centers it*/
}
</style>
