// vue
import { ref } from 'vue'

// types
import type { LoginPayload, User } from '@revolutionprep/types'

// nuxt
import { useNuxtApp, navigateTo, type NuxtError } from '#app'

// composables
import { useResetPiniaStore } from '../composables'

export function useAuth () {
  /**
   * reset pinia stores
   * ==================================================================
   */
  const { doResetPiniaStore } = useResetPiniaStore()

  /**
   * state
   * ==================================================================
   */
  const isProcessing = ref(false)

  /**
   * login
   * ==================================================================
   */
  async function doLogin (loginPayload?: LoginPayload | undefined) {
    try {
      isProcessing.value = true
      const { $orbitApiFetch } = useNuxtApp()
      const { user } = await $orbitApiFetch<{ user: User }>(
        '/login',
        {
          method: 'POST',
          body: loginPayload
        }
      )
      return user
    } catch (error) {
      const { $toast } = useNuxtApp()
      $toast.error((error as NuxtError<unknown>).message)
      await navigateTo('/login')
    } finally {
      isProcessing.value = false
    }
  }

  /**
   * logout
   * ==================================================================
   */
  async function doLogout () {
    try {
      isProcessing.value = true
      const { $orbitApiFetch } = useNuxtApp()
      await $orbitApiFetch('/logout', { method: 'DELETE' })
      doResetPiniaStore()
    } finally {
      await navigateTo('/login')
      isProcessing.value = false
    }
  }

  return {
    doLogin,
    doLogout,
    isProcessing
  }
}
