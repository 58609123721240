<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g
      transform="translate(8 20) scale(0.5)"
    >
      <g
        id="Eyes/Surprised-😳"
        transform="translate(0.000000, 8.000000)"
      >
        <circle
          id="The-White-Stuff"
          fill="#FFFFFF"
          cx="30"
          cy="22"
          r="14"
        />
        <circle
          id="Eye-Ball"
          fill="#FFFFFF"
          cx="82"
          cy="22"
          r="14"
        />
        <circle
          class="CustomColor"
          fill="#000000"
          cx="30"
          cy="22"
          r="6"
        />
        <circle
          class="CustomColor"
          fill="#000000"
          cx="82"
          cy="22"
          r="6"
        />
      </g>
    </g>
  </svg>
</template>
