// types
import type { RouteLocationNormalized } from 'vue-router'

// utilities
import { joinURL } from 'ufo'

export default defineNuxtPlugin((nuxtApp) => {
  /**
   * app error
   * ==================================================================
  */
  nuxtApp.hook('app:error', (error) => {
    const { doHandleError } = useErrorHandler()
    doHandleError(error as Error)
  })

  /**
   * vue error
   * ==================================================================
   */
  nuxtApp.hook('vue:error', (error) => {
    const { doHandleError } = useErrorHandler()
    doHandleError(error as Error)
  })

  /**
   * chunk error
   * ==================================================================
   */
  // perform a hard reload when a chunk fails to load during route navigation
  // https://nuxt.com/docs/getting-started/error-handling#errors-with-js-chunks
  const router = useRouter()
  const config = useRuntimeConfig()

  const chunkErrors = new Set()

  router.beforeEach(() => { chunkErrors.clear() })
  nuxtApp.hook('app:chunkError', ({ error }) => {
    chunkErrors.add(error)
  })

  function reloadAppAtPath (to: RouteLocationNormalized) {
    const isHash = 'href' in to && (to.href as string)[0] === '#'
    const path = isHash
      ? config.app.baseURL + (to as any).href
      : joinURL(config.app.baseURL, to.fullPath)
    reloadNuxtApp({ path, persistState: true })
  }

  nuxtApp.hook('app:manifest:update', () => {
    router.beforeResolve(reloadAppAtPath)
  })

  router.onError((error, to) => {
    if (chunkErrors.has(error)) {
      reloadAppAtPath(to)
    }
  })
})
