import * as Sentry from '@sentry/vue'
import pkg from '~~/package.json'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  setup (nuxtApp) {
    const config = useRuntimeConfig()
    const vueApp = nuxtApp.vueApp
    const router = useRouter()
    const isDebug = config.public.appDebug === 'true'
    const isSentryEnabled = config.public.sentryEnable === 'true'
    const isAppStageNotLocal = config.public.appStage !== 'local'

    if (
      process.client &&
      isSentryEnabled &&
      !isDebug &&
      isAppStageNotLocal
    ) {
      Sentry.init({
        app: vueApp,
        dsn: config.public.sentryDsn,
        denyUrls: [
          /graph\.facebook\.com/i,
          /connect\.facebook\.net\/en_US\/all\.js/i,
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          /extensions\//i,
          /^chrome:\/\//i,
          /127\.0\.0\.1:4001\/isrunning/i,
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
        ],
        environment: config.public.appStage,
        release: pkg.version,
        hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        trackComponents: true,
        tracesSampleRate: 0.5,
        timeout: 2000,
        integrations: [
          Sentry.browserTracingIntegration({ router }),
          Sentry.replayIntegration({
            blockAllMedia: true,
            maskAllText: true
          })
        ]
      })
    }
  }
})
