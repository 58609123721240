// vue
import { computed, ref } from 'vue'

// nuxt
import { useRuntimeConfig } from '#app'

// types
import type { ModuleOptions } from '@nuxt/schema'

// pusher
import Pusher from 'pusher-js'

// logger
import consolaLogger from 'consola'

export function usePusher (options: ModuleOptions) {
  /**
   * runtime config
   * ==================================================================
   */
  const config = useRuntimeConfig()

  /**
   * pusher client
   * ==================================================================
   */
  const client = ref<Pusher>()

  /**
   * logger
   * ==================================================================
   */
  const logger = consolaLogger.withTag('Pusher')

  /**
   * computed
   * ==================================================================
   */
  const isDev = computed(() => {
    return config.public.appStage !== 'production'
  })

  /**
   * methods
   * ==================================================================
   */
  function init () {
    client.value = new Pusher(options.appKey, options.options)
    if (isDev.value) {
      Pusher.log = (message): void => {
        logger.log(message.replace('Pusher :  : ', ''))
      }
    }
  }

  function subscribeChannel (channelName: string) {
    let channel = client.value?.channel(channelName)
    if (!channel?.subscribed) {
      channel = client.value?.subscribe(channelName)
      if (isDev.value && options.debug) {
        logger.log(`subscribed to: ${channelName}`)
      }
    }
    return channel
  }

  function unsubscribeChannel (channelName: string) {
    client.value?.unsubscribe(channelName)
    if (isDev.value && options.debug) {
      logger.log(`unsubscribed from: ${channelName}`)
    }
  }

  function bindEvent (
    event: string,
    channelName: string,
    callback: Function
  ) {
    const channel = subscribeChannel(channelName)
    if (channel) {
      return channel.bind(event, callback)
    }
  }

  function unbindEvent (
    event: string,
    channelName: string,
    callback?: Function
  ) {
    const channel = subscribeChannel(channelName)
    if (channel) {
      return channel.unbind(event, callback)
    }
  }

  return {
    init,
    subscribeChannel,
    unsubscribeChannel,
    bindEvent,
    unbindEvent
  }
}
