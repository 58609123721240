<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Layer_2"
      transform="translate(12 10) scale(0.26)"
      data-name="Layer 2"
    >
      <g
        id="Layer_1-2"
        data-name="Layer 1"
      >
        <path d="M140,168.68A71.51,71.51,0,0,0,112,163h-4V144.63a55.91,55.91,0,0,0,31.79-45.74A12,12,0,0,0,150,87V74a12,12,0,0,0-10-11.83V56A56,56,0,1,0,28,56v6.17A12,12,0,0,0,18,74V87A12,12,0,0,0,28.18,98.89,56,56,0,0,0,60,144.63V163H56A71.87,71.87,0,0,0,0,189.74a120,120,0,0,0,167.94,0A72.2,72.2,0,0,0,140,168.68Z" />
      </g>
    </g>
  </svg>
</template>
