<script setup lang="ts">
import type { NuxtError } from '#app'
import type { ErrorResponse } from '@revolutionprep/types'

interface Props {
  error: Error | ErrorResponse | NuxtError
}
const props = defineProps<Props>()
</script>

<template>
  <v-container style="height: 100vh;">
    <r-error-display :error="props.error" />
  </v-container>
</template>
