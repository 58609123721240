<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g
      transform="translate(-9 -22) scale(0.8)"
    >
      <g
        id="Mouth/Serious"
        transform="translate(2.000000, 52.000000)"
        fill="#000000"
        opacity="0.699999988"
      >
        <rect
          id="Why-so-serious?"
          x="42"
          y="18"
          width="24"
          height="6"
          rx="3"
        />
      </g>
    </g>
  </svg>
</template>
