<script lang="ts" setup>
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 5H4.375C3.33947 5 2.5 5.83947 2.5 6.875V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H13.125C14.1605 17.5 15 16.6605 15 15.625V8.75M6.25 13.75L17.5 2.5M17.5 2.5L13.125 2.5M17.5 2.5V6.875"
      stroke="currentColor"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
