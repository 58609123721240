// types
import type { PiniaPluginContext } from 'pinia'

// utils
import { deepClone } from '@revolutionprep/utils'

function resetStore ({ store }: PiniaPluginContext) {
  const initialState = deepClone(store.$state)
  store.$reset = () => store.$patch(deepClone(initialState))
}

export default defineNuxtPlugin(() => {
  const { $pinia } = useNuxtApp()
  $pinia.use(resetStore)
})
