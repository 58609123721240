// vue
import { ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// types
import type {
  HeadsUpNotice,
  Notice,
  Nullable
} from '@revolutionprep/types'

export const useNoticeStore = defineStore('notice', () => {
  /**
   * state
   * ==================================================================
   */
  const headsUpNotice = ref<Nullable<HeadsUpNotice>>(null)
  const newNotice = ref<Nullable<Notice>>(null)
  const showHeadsUpNoticeSnackbar = ref(false)
  const showNewNoticeSnackbar = ref(false)

  return {
    headsUpNotice,
    newNotice,
    showHeadsUpNoticeSnackbar,
    showNewNoticeSnackbar
  }
})
