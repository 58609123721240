<script lang="ts" setup>
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.9974 18.3307C14.5998 18.3307 18.3307 14.5998 18.3307 9.9974C18.3307 5.39502 14.5998 1.66406 9.9974 1.66406C5.39502 1.66406 1.66406 5.39502 1.66406 9.9974C1.66406 14.5998 5.39502 18.3307 9.9974 18.3307Z"
      stroke="currentColor"
      stroke-width="1.04167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.57812 7.49852C7.77405 6.94158 8.16075 6.47194 8.66975 6.1728C9.17876 5.87365 9.7772 5.7643 10.3591 5.86411C10.941 5.96393 11.4688 6.26646 11.849 6.71813C12.2292 7.1698 12.4373 7.74146 12.4365 8.33185C12.4365 9.99852 9.93646 10.8319 9.93646 10.8319"
      stroke="currentColor"
      stroke-width="1.04167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10 14.1641H10.0083"
      stroke="currentColor"
      stroke-width="1.04167"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
