<script setup lang="ts">
// vue
import { computed, ref } from 'vue'

// nuxt
import { useNuxtApp, useRuntimeConfig } from '#app'

// types
import type { NuxtError } from '#app'
import type { ErrorResponse, UserRole } from '@revolutionprep/types'

// utilities
import { formatPhone, hasRoles } from '@revolutionprep/utils'

/**
 * props
 * ==================================================================
 */
interface Props {
  error?: Error | ErrorResponse | NuxtError | undefined
  homeUrl?: string
}
const props = withDefaults(defineProps<Props>(), {
  error: undefined,
  homeUrl: '/'
})

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * state
 * ==================================================================
 */
const customerServiceEmail = ref('customerservice@revolutionprep.com')

/**
 * computed
 * ==================================================================
 */
const errorStatusCode = computed(() => {
  if (props.error) {
    return 'statusCode' in props.error
      ? props.error?.statusCode
      : 0
  }
  return 0
})

const errorMessage500 = computed(() => {
  if (isAdmin.value) {
    return `If you believe this was received in error, please create a
    ticket using the button below.`
  } else {
    return `If you believe this was received in error, please contact
    Customer Service at ${
      formatPhone(config.public.companyPhone as string)
    } or `
  }
})

const errorTitle = computed(() => {
  switch ((props.error as NuxtError).statusCode) {
    case 404:
      return 'Page Not Found'
    case 401:
      return 'Permission Denied'
    default:
      return 'Yikes! Something went wrong...'
  }
})

const isAdmin = computed(() => {
  const user = $actor.core.user.value
  if (!user || !user.roles) {
    return false
  }
  return hasRoles('admin' as UserRole, user.roles)
})
</script>

<template>
  <v-row style="height: 100%;">
    <v-col
      class="d-flex justify-center align-center text-center"
      align-self="center"
    >
      <v-sheet
        color="transparent"
        class="pa-5"
        width="640"
      >
        <div>
          <h1 class="text-h5 font-weight-bold mb-4">
            {{ errorTitle }}
          </h1>
          <div>
            <v-alert
              v-if="error?.message"
              icon="mdi-alert"
              prominent
              variant="tonal"
              type="error"
              class="text-start"
            >
              <p class="text-red ma-0">
                {{ error.message }}
              </p>
            </v-alert>
            <div class="my-4">
              <p v-if="(errorStatusCode || 0) >= 500">
                <span>{{ errorMessage500 }}</span>
                <a
                  v-if="!isAdmin"
                  :href="`mailto:${customerServiceEmail}`"
                >
                  {{ customerServiceEmail }}
                </a>
              </p>
              <p v-else>
                It looks like you may have taken a wrong turn somewhere...
              </p>
            </div>
          </div>
          <div class="d-flex justify-center">
            <v-btn
              v-if="(errorStatusCode || 0) >= 500 && isAdmin && config"
              class="me-3"
              :href="(config.public.supportUrl as string)"
            >
              Create a support ticket
            </v-btn>
            <v-btn :href="homeUrl">
              Go back to home
            </v-btn>
          </div>
        </div>
        <img
          v-if="(errorStatusCode || 0) >= 500"
          src="../assets/images/error/error-500.svg"
          width="100%"
          alt="error-500"
        >
        <img
          v-else
          src="../assets/images/error/error-404.svg"
          width="100%"
          alt="error-404"
        >
      </v-sheet>
    </v-col>
  </v-row>
</template>
