<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Clothing/Overall"
      transform="translate(-24 21) scale(0.43)"
    >
      <defs>
        <path
          id="Overall-path1"
          d="M94,29.6883435 L94,74 L170,74 L170,29.6883435 C179.362956,30.9893126 188.149952,34.0907916 196.00002,38.6318143 L196,110 L187,110 L77,110 L68,110 L68,38.6318027 C75.8500482,34.0907916 84.6370437,30.9893126 94,29.6883435 Z"
        />
      </defs>
      <mask
        id="Overall-mask1"
        fill="white"
      >
        <use href="#Overall-path1" />
      </mask>
      <use
        class="CustomColor"
        fill="var(--avataaar-shirt-color)"
        href="#Overall-path1"
      />
      <circle
        id="Button"
        fill="#F4F4F4"
        fillRule="evenodd"
        cx="81"
        cy="83"
        r="5"
      />
      <circle
        id="Button"
        fill="#F4F4F4"
        fillRule="evenodd"
        cx="183"
        cy="83"
        r="5"
      />
    </g>
  </svg>
</template>

<style scoped>
.circle {
  width: 64px;
  height: 64px;
  border-radius:  100%;
  overflow: hidden;
  margin: 0 auto;/* centers it*/
}
</style>
