<script lang="ts" setup>
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33594 16.2474V3.7474C3.33594 3.19486 3.55543 2.66496 3.94613 2.27426C4.33683 1.88356 4.86674 1.66406 5.41927 1.66406H15.8359C16.057 1.66406 16.2689 1.75186 16.4252 1.90814C16.5815 2.06442 16.6693 2.27638 16.6693 2.4974V17.4974C16.6693 17.7184 16.5815 17.9304 16.4252 18.0867C16.2689 18.2429 16.057 18.3307 15.8359 18.3307H5.41927C4.86674 18.3307 4.33683 18.1112 3.94613 17.7205C3.55543 17.3298 3.33594 16.7999 3.33594 16.2474ZM3.33594 16.2474C3.33594 15.6949 3.55543 15.165 3.94613 14.7743C4.33683 14.3836 4.86674 14.1641 5.41927 14.1641H16.6693"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.66406 10.8333L9.9974 5L13.3307 10.8333"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.58594 9.16406H12.3359"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
