<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g
      transform="translate(8 20) scale(0.5)"
    >
      <g
        id="Eyes/Squint-😊"
        transform="translate(0.000000, 8.000000)"
      >
        <defs>
          <path
            id="Eye1"
            d="M14,14.0481187 C23.6099827,14.0481187 28,18.4994466 28,11.5617716 C28,4.62409673 21.7319865,0 14,0 C6.2680135,0 0,4.62409673 0,11.5617716 C0,18.4994466 4.39001726,14.0481187 14,14.0481187 Z"
          />
          <path
            id="Eye2"
            d="M14,14.0481187 C23.6099827,14.0481187 28,18.4994466 28,11.5617716 C28,4.62409673 21.7319865,0 14,0 C6.2680135,0 0,4.62409673 0,11.5617716 C0,18.4994466 4.39001726,14.0481187 14,14.0481187 Z"
          />
        </defs>
        <g
          id="Eye"
          transform="translate(16.000000, 13.000000)"
        >
          <mask
            id="EyeMask1"
            fill="white"
          >
            <use href="#Eye1" />
          </mask>
          <use
            id="The-white-stuff"
            fill="#FFFFFF"
            href="#Eye1"
          />
          <circle
            class="CustomColor"
            fill="#000000"
            mask="url('#EyeMask1')"
            cx="14"
            cy="10"
            r="6"
          />
        </g>
        <g
          id="Eye"
          transform="translate(68.000000, 13.000000)"
        >
          <mask
            id="EyeMask2"
            fill="white"
          >
            <use href="#Eye2" />
          </mask>
          <use
            id="Eyeball-Mask"
            fill="#FFFFFF"
            href="#Eye2"
          />
          <circle
            class="CustomColor"
            fill="#000000"
            mask="url('#EyeMask2')"
            cx="14"
            cy="10"
            r="6"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
