// vue
import { ref } from 'vue'

// types
import type {
  Enrollment,
  Nullable,
  Tutor
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

// utilities
import { compareAsc } from 'date-fns'

// pinia
import { defineStore } from 'pinia'

// nuxt
import { useNuxtApp } from '#app'

export const useEnrollmentStore = defineStore('enrollment', () => {
  // fetch repositories
  const { $enrollments } = useNuxtApp()

  // state
  const enrollment = ref<Nullable<Enrollment>>(null)
  const enrollments = ref<Enrollment[]>([])

  // actions
  const index = async (config?: NitroFetchOptions<string>) => {
    const { enrollments: _enrollments } =
      await $enrollments.index<{ enrollments: Enrollment[] }>(config)
    enrollments.value = _enrollments || []
    return enrollments
  }

  const show = async (
    id: number,
    config?: NitroFetchOptions<string>
  ) => {
    const { enrollment: _enrollment } =
      await $enrollments.show<{ enrollment: Enrollment }>(id, config)
    enrollment.value = _enrollment
    return enrollment
  }

  // getters
  function getSelfStudyEnrollments () {
    return enrollments.value.filter((enrollment) => {
      return enrollment.brand?.handle.includes('self-study')
    })
  }

  function getSortedEnrollments () {
    return [...enrollments.value].sort((a, b) => {
      return compareAsc(new Date(a.createdAt), new Date(b.createdAt))
    })
  }

  function getTutoringEnrollments () {
    return getSortedEnrollments().filter((enrollment) => {
      return enrollment.course?.tutorScheduling
    })
  }

  function getTutors () {
    return getTutoringEnrollments().reduce((tutors, enrollment) => {
      if (
        (
          enrollment.title === 'Private Tutoring' ||
          enrollment.title === 'Academics' ||
          enrollment.title === 'Back-Up Care Tutoring'
        ) &&
        enrollment.tutors
      ) {
        tutors = tutors.concat(enrollment.tutors)
      } else if (enrollment.course?.host) {
        tutors.push(enrollment.course?.host)
      }
      return tutors
    }, [] as Partial<Tutor>[])
  }

  return {
    enrollment,
    enrollments,
    getSelfStudyEnrollments,
    getSortedEnrollments,
    getTutoringEnrollments,
    getTutors,
    index,
    show
  }
})
