<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Clothing/Graphic-Shirt"
      transform="translate(-24 21) scale(0.43)"
    >
      <defs>
        <path
          id="react-path-1153"
          d="M165.624032,29.2681342 C202.760022,32.1373245 232,63.1798426 232,101.051724 L232,110 L32,110 L32,101.051724 C32,62.8348009 61.7752018,31.5722494 99.3929298,29.1967444 C99.1342224,30.2735458 99,31.3767131 99,32.5 C99,44.3741221 113.998461,54 132.5,54 C151.001539,54 166,44.3741221 166,32.5 C166,31.4015235 165.871641,30.3222877 165.624025,29.2681336 Z"
        />
      </defs>
      <mask
        id="react-mask-1154"
        fill="white"
      >
        <use xlink:href="#react-path-1153" />
      </mask>
      <use
        id="Clothes"
        fill="#E6E6E6"
        fill-rule="evenodd"
        xlink:href="#react-path-1153"
      />
      <g
        id="Color/Palette/"
        mask="url(#react-mask-1154)"
        fill-rule="evenodd"
        fill="var(--avataaar-shirt-color)"
      >
        <rect
          id="🖍Color"
          x="0"
          y="0"
          width="264"
          height="110"
        />
      </g>
      <g
        id="Clothing/Graphic/Cumbia"
        stroke-width="1"
        fill-rule="evenodd"
      >
        <g
          id="Group"
          transform="translate(77.000000, 58.000000)"
        >
          <g transform="translate(0.000000, 6.000000)">
            <path
              id="Fill-1"
              d="M10.271566,24.1315073 C13.5453197,23.5731503 16.0029461,20.5783262 15.4519585,17.3443595 C14.9878717,14.6197774 13.7065483,17.0011951 12.4758861,18.1969085 C11.1405733,19.4948562 10.0263951,20.7706412 7.9400381,20.2508902 C4.33273358,19.3518711 3.08247252,14.8503409 4.09976568,11.7711556 C4.65593031,10.0878629 5.87660814,8.76060323 7.57838317,8.07498941 C9.42992328,7.32896437 10.7818767,8.16542752 12.3264908,9.1691833 C12.6145575,9.35649386 14.0556303,10.5386236 14.3204002,10.4181586 C14.7822683,10.2079704 14.435405,7.98955578 14.3651448,7.69393398 C14.0267867,6.27266155 13.0993526,5.11877135 11.7481388,4.45603516 C8.41484886,2.82099993 4.23436935,4.94468693 2.10881463,7.51091298 C-2.77648522,13.4083357 1.18803674,25.6753895 10.271566,24.1315073"
              fill="#FFFFFF"
            />
            <path
              id="Fill-5"
              d="M20.283037,5.0382162 C20.2819296,5.04100097 20.2826679,5.03926048 20.283037,5.0382162 M18.6886879,17.9031695 C19.1918363,21.3771733 21.6658646,24.4038731 25.6412168,24.2597611 C29.9192703,24.1048581 31.6970859,20.1616201 32.6494871,16.7739442 C33.6055798,13.3751292 34.7008413,9.09110464 33.3132148,5.68149867 C32.8931247,4.64939232 32.6284457,3.30051808 31.5966777,4.15126612 C30.3445285,5.18372056 30.188379,8.18953451 30.0824336,9.59271181 C29.8819863,12.2396382 29.3005786,19.5639383 25.9741881,20.5368682 C21.7954354,21.7593834 21.9264829,14.6895419 21.9733647,12.559887 C22.0147092,10.6641531 22.2203245,8.82550695 21.6322721,6.97572174 C21.3192348,5.99095653 21.0416357,4.53521664 20.1036312,5.34175641 C18.8141981,6.45044401 18.6506656,9.16977447 18.5602244,10.6690264 C18.4155185,13.0736776 18.3464879,15.5110498 18.6886879,17.9031695"
              fill="#FFFFFF"
            />
            <path
              id="Fill-7"
              d="M37.7827156,20.7457507 C37.9712859,21.1583285 38.4071734,22.1476129 38.8028084,22.4248624 C39.7471104,23.085889 38.7480505,23.1327334 39.6027816,22.3662202 C40.4161723,21.6371824 40.7331154,19.6485506 40.8571366,18.6953537 C41.2422552,15.7354812 40.7367418,12.5875371 40.7704668,9.60302793 C41.7938233,11.8206768 42.3544573,14.1868398 43.1555184,16.4766638 C43.7096249,18.0589637 44.5516638,21.2808179 46.809793,21.2301566 C49.2532288,21.1753312 49.3790632,18.0849884 49.7043469,16.4110816 C50.1652562,14.0411017 50.674396,11.6898595 51.3797214,9.37401083 C51.4718308,13.2836103 49.952752,20.3692605 53.4880823,23.2940864 C53.5018625,23.3072722 54.9204913,19.144366 54.954579,18.8882832 C55.1783249,17.1970267 55.0383477,15.4440049 55.0622816,13.7402565 C55.1130505,10.1433001 55.779574,5.73680294 54.762745,2.23422934 C54.4338349,1.10024771 53.7872564,-0.0302639607 52.3530342,0.000618647874 C50.5278914,0.0401761465 50.1202895,1.98612748 49.6550286,3.29915884 C48.3742011,6.91277103 47.2097796,10.5229133 46.2981148,14.2395832 C45.7498104,12.5594305 40.9593998,-2.18198369 37.5092887,3.33767535 C36.9540943,4.22633108 37.1937961,5.55601823 37.1709501,6.53385184 C37.1274339,8.41283302 37.0161049,10.2897322 36.9754898,12.1690604 C36.9138418,15.0116484 36.5653494,18.0756195 37.7827156,20.7457507"
              fill="#FFFFFF"
            />
            <path
              id="Fill-9"
              d="M62.0203329,7.70997634 C62.739914,7.57453437 67.7552707,5.98272311 67.5433251,7.57379827 C67.3203514,9.24915661 62.9098151,10.8829254 61.7267107,11.4497207 C61.7246429,10.2414163 61.4930536,9.00918877 61.0839813,7.88259125 C61.3958688,7.82517563 61.7081008,7.76739196 62.0203329,7.70997634 M67.7366608,7.0706755 C67.7370055,7.06957135 67.7101246,7.03497476 67.7366608,7.0706755 M67.8590034,15.4110304 C70.1314738,16.6318484 69.1461853,18.8279958 67.4326998,20.0186339 C66.7844565,20.4691256 60.9030521,21.8209691 60.9195942,21.6818466 C60.9202835,21.6855271 60.9192496,21.6869993 60.9164926,21.6866312 C61.0960433,19.9925024 60.6587116,16.6686533 61.9159109,15.6664563 C63.2203241,14.6267183 66.4163948,14.8593252 67.8590034,15.4110304 M67.9210363,7.41553727 C67.9299966,7.4383563 67.9220702,7.41811361 67.9210363,7.41553727 M57.9444294,24.2596607 C58.1677477,24.8109979 58.8039291,26.1720425 59.5100698,26.2047988 C60.3706034,26.2449162 60.3040904,25.1617484 60.4388396,24.49558 C63.8802848,26.2162084 68.9369967,24.4481017 71.346284,21.4654339 C74.1325933,18.014976 72.6941202,13.1953763 68.7674402,11.7202366 C70.879659,10.0319965 72.7933728,6.32096 70.0222271,4.23081061 C67.8262639,2.57459081 64.19424,2.48294665 61.8993688,3.92827446 C59.1637197,5.65111111 58.0495406,9.7614806 57.8096802,12.9340616 C57.553967,16.3156944 56.6513889,21.0591079 57.9444294,24.2596607"
              fill="#FFFFFF"
            />
            <path
              id="Fill-11"
              d="M76.0506048,15.8714133 C76.1237655,17.940757 75.9002189,20.1580872 76.3823293,22.1766928 C76.5533503,22.8940747 76.8153532,23.6882686 77.1423879,24.3390566 C77.7529985,25.5536025 77.4525393,25.3897603 78.1744543,24.6980999 C80.3464523,22.6164239 79.3828568,16.1236949 79.3306438,13.4500732 C79.2896863,11.3715683 79.3947376,9.17044617 78.8182061,7.16734396 C78.656252,6.60499548 77.6954704,3.81897451 77.1633356,3.88345432 C76.3469995,3.98140725 75.7873513,7.81319908 75.7401407,8.57603396 C75.592256,10.9723573 75.9658759,13.4712141 76.0506048,15.8714133"
              fill="#FFFFFF"
            />
            <path
              id="Fill-13"
              d="M94.7477626,16.430477 C93.1655372,16.2926323 91.1264105,16.5045376 89.6311966,16.9871715 C90.3280165,15.0708102 91.1056588,12.9329761 91.8694667,11.1973368 C92.343479,10.1066267 92.8411555,9.02761048 93.3628603,7.95745314 C94.6410916,10.6431228 95.3546586,13.7848497 96.19492,16.6232471 C95.7158108,16.535721 95.233425,16.4715824 94.7477626,16.430477 M100.188346,17.1547822 C99.4605809,14.375208 98.6093975,11.6225649 97.7622188,8.8759458 C97.2244951,7.13215631 96.6321618,4.96384744 95.1624324,3.70729846 C90.9986238,0.146013273 88.6354794,9.55701945 87.6142778,11.9390054 C86.6294827,14.2352371 85.3978516,16.5725741 84.764743,18.9878696 C84.4541957,20.1714211 84.3555342,21.4261983 84.5197274,22.6402245 C84.725424,24.1589974 84.5157227,24.3811791 85.8136135,23.5544648 C86.8140635,22.9176858 87.2280051,21.7617741 88.0409617,20.9917569 C88.1847673,20.8556839 88.2586724,20.3241488 88.4301469,20.2373314 C88.6194606,20.1413008 89.9348266,20.4896334 90.2461019,20.5112492 C92.4155638,20.6622052 94.958192,20.2933198 96.9605482,19.4573923 C97.1658807,20.2989895 98.5882817,25.4187356 99.9378698,25.2302178 C100.5353,25.1465897 100.903369,22.1660952 100.929946,21.6933833 C101.017322,20.1398833 100.57826,18.6444979 100.188346,17.1547822"
              fill="#FFFFFF"
            />
            <path
              id="Fill-15"
              d="M109.301672,3.43213272 C109.038492,2.22890734 108.487555,0.14016215 107.459721,1.31990649 C106.069159,2.9162614 106.369359,6.49285063 106.348516,8.49692176 C106.333584,9.94847693 104.797121,20.5594163 106.905363,20.3801066 C106.815459,20.3801066 107.750277,18.7118855 107.885912,18.4578633 C108.700028,16.9340861 109.016405,15.4885791 109.213945,13.735684 C109.578851,10.499214 109.997264,6.62484253 109.301672,3.43213272"
              fill="#FFFFFF"
            />
            <path
              id="Fill-17"
              d="M108.164017,24.3012978 C105.924782,21.573307 101.852706,24.9591601 103.122505,27.675428 C104.85153,31.3753862 110.454236,27.1147969 108.164017,24.3012978"
              fill="#FFFFFF"
            />
            <path
              id="Fill-19"
              d="M94.8984918,28.5446042 C92.0066895,27.8070876 88.6012921,28.3048186 85.6491092,28.3933947 C82.5678984,28.4853064 79.489563,28.6568994 76.4083523,28.7499229 C69.8412367,28.9481999 63.2849033,28.8418345 56.7170689,28.7877252 C44.2631983,28.6854365 31.7816533,29.4770624 19.334252,28.9556122 C16.661689,28.8440582 13.7889353,28.2429265 11.1361398,28.7473286 C10.409056,28.8859372 8.12717022,29.2902742 7.80729649,30.013337 C7.47088995,30.7730903 9.20934979,31.5680517 10.1384213,31.968312 C12.560117,33.0119536 15.4670145,32.8262773 18.0389431,32.9319015 C20.9688428,33.0527208 23.9249793,32.9900875 26.856676,32.9181889 C38.9324486,32.6213292 50.9456841,31.5750933 63.0376301,31.7529868 C70.0126746,31.8560167 76.974421,31.7911597 83.9494655,31.7544692 C87.2686047,31.7366799 90.9489497,32.2799961 94.2217253,31.6922065 C94.773777,31.5928827 97.9847343,30.8375767 98.0293009,29.8561978 C98.0497872,29.4033107 95.2233973,28.6276211 94.8984918,28.5446042"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.circle {
  width: 64px;
  height: 64px;
  border-radius:  100%;
  overflow: hidden;
  margin: 0 auto;/* centers it*/
}
</style>
