// vue
import { ref } from 'vue'

// pinia
import { defineStore } from 'pinia'

// types
import type { Actor, Nullable } from '@revolutionprep/types'

export const useActorStore = defineStore('actor', () => {
  // state
  const dynamicState = ref<Record<string, unknown>>({
    actor: null as Nullable<Actor>,
    isProcessing: false
  })

  return {
    dynamicState
  }
})
