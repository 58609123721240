import type { ErrorHash } from '@revolutionprep/types'
import toSentence from './toSentence'
import mapErrors from './mapErrors'

/**
 * Utility to format an error object or array to a readable sentence
 *
 * @param {ErrorHash | string[]} errors
 * @param {type} string
 * @returns {string}
 */

export default function (
  errors: ErrorHash | Partial<Record<string, string>> | string[],
  type: string
) {
  return Array.isArray(errors)
    ? toSentence(errors)
    : toSentence(mapErrors(errors, type))
}
