<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Clothing/Shirt-Scoop-Neck"
      transform="translate(-24 21) scale(0.43)"
    >
      <defs>
        <path
          id="ShirtScoopNeck-path1"
          d="M181.544255,32.3304167 C210.784209,41.4878872 232,68.7921987 232,101.051724 L232,110 L32,110 L32,101.051724 C32,68.3969699 53.7388273,40.8195914 83.5340368,32.0020332 C83.182234,33.4201865 83,34.8712315 83,36.3476251 C83,52.6289957 105.161905,65.8276544 132.5,65.8276544 C159.838095,65.8276544 182,52.6289957 182,36.3476251 C182,34.9849859 181.844766,33.6439396 181.544255,32.3304167 Z"
        />
      </defs>
      <mask
        id="ShirtScoopNeck-mask1"
        fill="white"
      >
        <use href="#ShirtScoopNeck-path1" />
      </mask>
      <use
        class="CustomColor"
        fill="var(--avataaar-shirt-color)"
        href="#ShirtScoopNeck-path1"
      />
    </g>
  </svg>
</template>

<style scoped>
.circle {
  width: 64px;
  height: 64px;
  border-radius:  100%;
  overflow: hidden;
  margin: 0 auto;/* centers it*/
}
</style>
