import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "enrollment-wizard": () => import("/vercel/path0/apps/student/src/middleware/enrollmentWizard.ts"),
  "tutor-matching-possible-events": () => import("/vercel/path0/apps/student/src/middleware/tutorMatchingPossibleEvents.ts"),
  "tutor-matching-validation": () => import("/vercel/path0/apps/student/src/middleware/tutorMatchingValidation.ts"),
  "validate-route-roles": () => import("/vercel/path0/apps/student/src/middleware/validateRouteRoles.ts")
}