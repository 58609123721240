<script setup lang="ts" />

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="meet-focus-content-svgrepo-com 1">
      <path
        id="Vector"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.56923 12.6508C5.09436 12.6508 5.59798 12.8594 5.9693 13.2307C6.34063 13.602 6.54923 14.1056 6.54923 14.6308V14.8061C6.55451 15.0954 6.49889 15.3826 6.38598 15.6489C6.27307 15.9153 6.1054 16.1549 5.89385 16.3523C5.76644 16.4528 5.66187 16.5793 5.58707 16.7233C5.51228 16.8674 5.46896 17.0256 5.46 17.1877C5.46 17.4554 5.57077 17.7231 6.15231 18.0231L6.74769 18.2908C7.58308 18.7015 8.31231 19.1261 8.33539 19.9615C8.32485 20.2199 8.22387 20.4664 8.05009 20.6579C7.87631 20.8494 7.64077 20.9737 7.38462 21.0092H1.86923C1.57908 20.9984 1.30491 20.8736 1.10618 20.6619C0.907454 20.4502 0.800203 20.1687 0.807695 19.8785C0.807695 19.0846 1.54154 18.6185 2.39539 18.2077L2.70462 18.0738L2.85693 18C3.58154 17.7 3.69231 17.4369 3.69231 17.1692C3.66105 16.8452 3.50557 16.5458 3.25846 16.3338C3.04433 16.1346 2.87512 15.892 2.76211 15.6222C2.6491 15.3524 2.59489 15.0616 2.60308 14.7692C2.56227 14.2455 2.73077 13.727 3.07164 13.3273C3.41251 12.9276 3.89794 12.6793 4.42154 12.6369L4.56923 12.6508ZM21.2908 3.4523C21.5102 3.4523 21.7274 3.49552 21.9301 3.57948C22.1329 3.66344 22.317 3.78651 22.4722 3.94166C22.6273 4.0968 22.7504 4.28099 22.8344 4.48369C22.9183 4.6864 22.9615 4.90366 22.9615 5.12307V17.6631C22.9615 18.1066 22.7857 18.5321 22.4725 18.8461C22.1593 19.1602 21.7343 19.3372 21.2908 19.3385H9.52616C9.45422 18.9885 9.30829 18.6579 9.09814 18.369C8.88799 18.08 8.61848 17.8393 8.30769 17.6631H20.8385C20.9486 17.6631 21.0543 17.6193 21.1322 17.5414C21.2101 17.4635 21.2538 17.3579 21.2538 17.2477V6.37845C21.2538 6.26829 21.2101 6.16263 21.1322 6.08473C21.0543 6.00683 20.9486 5.96307 20.8385 5.96307H4.11693C4.06238 5.96307 4.00836 5.97381 3.95796 5.99469C3.90757 6.01556 3.86178 6.04616 3.8232 6.08473C3.78463 6.12331 3.75404 6.1691 3.73316 6.21949C3.71229 6.26989 3.70154 6.32391 3.70154 6.37845H3.69231V11.4969C3.02528 11.6594 2.43402 12.0454 2.01693 12.5908V5.12307C2.01815 4.67953 2.1952 4.25457 2.50926 3.94138C2.82333 3.62818 3.24877 3.4523 3.69231 3.4523H21.2908Z"
        fill="black"
      />
    </g>
  </svg>
</template>
