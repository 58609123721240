export const isUnset = (o: unknown): boolean => typeof o === 'undefined' || o === null || o === 'undefined'

export const isSet = (o: unknown): boolean => !isUnset(o)

export function encodeValue (val: unknown): string {
  if (typeof val === 'string') {
    return val
  }
  return JSON.stringify(val)
}

export function decodeValue (val: unknown): unknown {
  // Try to parse as json
  if (typeof val === 'string' && val !== 'undefined') {
    try {
      return JSON.parse(val)
    } catch (error) {
      // eslint-disable-next-line
      console.warn(
        '[Actor]: Unable to decode value, returning value as is: ',
        error
      )
      return val
    }
  }

  // Return as is
  return val
}
