<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g
      transform="translate(-9 -24) scale(0.8)"
    >
      <g
        id="Mouth/Disbelief"
        transform="translate(2.000000, 52.000000)"
        opacity="0.699999988"
        fill="#000000"
      >
        <path
          id="Mouth"
          d="M40,15 C40,22.7319865 46.2680135,29 54,29 L54,29 C61.7319865,29 68,22.7319865 68,15"
          transform="translate(54.000000, 22.000000) scale(1, -1) translate(-54.000000, -22.000000) "
        />
      </g>
    </g>
  </svg>
</template>
