// polyfill
import './polyfills/array.at'

// nuxt
import { defineNuxtPlugin, useNuxtApp } from '#app'

// vuetify
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify, type ThemeDefinition } from 'vuetify'

// icons
import { mdi } from 'vuetify/iconsets/mdi'

// custom icons
import { aliases, custom } from './iconsets/custom'

const customLightTheme: ThemeDefinition = {
  dark: false,
  variables: {
    'medium-emphasis-opacity': 1
  },
  colors: {
    // vuetify overrides
    primary: '#EF8535',
    secondary: '#296DC0',
    info: '#6AADDB',
    success: '#39b54a',
    warning: '#ea9948',
    error: '#E80909',
    // orange
    backgroundprimary: '#fbf1e9',
    orange: '#EF8535',
    // yellow
    warningYellow: '#DAAA00',
    yellow: '#f4c041',
    gold: '#F3C558',
    staryellow: '#EEBA1B',
    'warning-tonal': '#F8F2E3',
    'warning-text': '#AA8F04',
    // green
    green: '#27AE60',
    darkgreen: '#228D58',
    backgroundgreen: '#8FDF82',
    // blue
    digitalblue: '#454eca',
    digitallightblue: '#6c74e3',
    lightblue: '#548EDD',
    royalblue: '#2a6dc0',
    navy: '#121f3e',
    teal: '#087A86',
    // purple
    purple: '#ae14c9',
    violet: '#7043C1',
    // grey
    activeroutebackgroundgrey: '#F6F5F4',
    backgroundgrey: '#F7F7F7',
    backgroundlightgrey: '#FDFDFD',
    backgroundlightgrey2: '#FAFAFA',
    bordergrey: '#E4E4E4',
    bordermedgrey: '#DDD',
    darkgrey: '#222',
    defaultexamsgrey: '#515151',
    defaulticongrey: '#757575',
    inactiveGrey: '#5E5E5E',
    lightgrey: '#eee',
    'grey-default': '#7C7C7C',
    'grey-select': '#5E5E5E',
    'grey-300': '#DBDBDB',
    medgrey: '#999999',
    textdarkgrey: '#666666',
    textgrey: '#808180',
    // white
    white: '#fff',
    // black
    textblack: '#121212'
  }
}

export default defineNuxtPlugin({
  name: '@revolutionprep/components',
  dependsOn: ['@revolutionprep/actor'],
  setup () {
    const nuxtApp = useNuxtApp()
    const vuetify = createVuetify({
      ssr: true,
      theme: {
        defaultTheme: 'customLightTheme',
        variations: {
          colors: ['primary', 'secondary'],
          lighten: 4,
          darken: 4
        },
        themes: {
          customLightTheme
        }
      },
      icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
          mdi,
          custom
        }
      }
    })

    // use vuetify
    nuxtApp.vueApp.use(vuetify)
  }
})
