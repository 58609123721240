import type { UserRole } from '@revolutionprep/types'

/**
 * Utility to check if a user has specific roles
 *
 * @param {(string | string[])} role role or roles to check
 * @param {string[]} userRoles user roles array
 * @returns {boolean}
 */

export default function (role: UserRole | UserRole[] | undefined, userRoles: UserRole[] | undefined): boolean {
  if (!role || !userRoles) {
    return false
  }
  const roles = Array.isArray(role) ? role : [role]
  const filteredRoles = roles.filter(item => userRoles.includes(item as UserRole))
  return Boolean(filteredRoles.length)
}
