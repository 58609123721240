// vue
import { computed, reactive, ref } from 'vue'

// utilities
import { hasRoles } from '@revolutionprep/utils'

// types
import type { ModuleOptions } from '@nuxt/schema'
import type {
  Actor,
  ActorType,
  LoginPayload,
  Nullable,
  Spoofee,
  User
} from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// composables
import { useAuth } from '../auth/auth'
import { useErrorHandler } from '../composables'
import { useActorStorage } from './storage'

// nuxt
import {
  navigateTo,
  reloadNuxtApp,
  useNuxtApp,
  useRoute,
  useRouter
} from '#app'

export function useActorCore (options: ModuleOptions) {
  /**
   * actor storage
   * ==================================================================
   */
  const storage = useActorStorage(options)

  /**
   * actor auth
   * ==================================================================
   */
  const auth = useAuth()

  /**
   * route
   * ==================================================================
   */
  const route = useRoute()
  const router = useRouter()

  /**
   * error handler
   * ==================================================================
   */
  const { doHandleError } = useErrorHandler()

  /**
   * state
   * ==================================================================
   */
  const previousSpoofee = ref<Nullable<Spoofee>>(null)

  /**
   * actor
   * ==================================================================
   */
  const actor = computed(() => {
    return storage.dynamicState.value.actor as Nullable<Actor>
  })

  const actorId = computed(() => {
    return (
      isSpoofingAuthorized.value &&
      spoofee.value &&
      isValidSpoofingType(spoofee.value?.type)
    )
      ? spoofee.value.id
      : user.value?.actorId
  })

  const stagedActor = computed<ActorType>(() => {
    return options.type
  })

  /**
   * spoofing
   * ==================================================================
   */
  const spoofing = computed(() => {
    return storage.dynamicState.value.spoofing as Nullable<string>
  })

  const spoofee = computed(() => {
    if (!spoofing.value) {
      return null
    }
    return spoofing.value.split('-')
      .reduce<Spoofee>((spoofee, splitSpoofingString, index) => {
        if (index === 0) {
          spoofee.type = splitSpoofingString as ActorType
        }
        if (index === 1) {
          spoofee.id = Number(splitSpoofingString)
        }
        return spoofee
      }, {} as Spoofee)
  })

  const isSpoofingAuthorized = computed(() => {
    if (!options.allowedSpoofingRoles) {
      return false
    }
    const authUser = storage.dynamicState.value.user as Nullable<User>
    if (!authUser) {
      return false
    }
    return hasRoles(options.allowedSpoofingRoles, authUser.roles)
  })

  async function clearSpoof () {
    previousSpoofee.value = spoofee.value
    storage.removeUniversal('spoofing')
    storage.setUniversal('spoofing', null)
    await verify()
  }

  function isSpoofingParamValid (param: string | (string | null)[]) {
    if (!param || Array.isArray(param)) {
      return false
    }
    const regexValidSpoofParam = RegExp(`^(${options.type})-([0-9]*)$`)
    return regexValidSpoofParam.test(param)
  }

  function spoof (spoofee: Spoofee) {
    if (!spoofee) {
      return
    }
    storage.setUniversal('spoofing', `${spoofee.type}-${spoofee.id}`)

    // reload page to refetch new spoofee data
    if (process.client) {
      if (route.query.spoofing) {
        router.push(route.path)
      } else {
        reloadNuxtApp()
      }
    }
  }

  function isValidSpoofingType (type: Nullable<ActorType> | undefined) {
    if (!type) {
      return false
    }
    return options.type === type
  }

  /**
   * authentication
   * ==================================================================
   */
  async function doLogin (loginPayload?: LoginPayload) {
    const spoofingRouteQuery = route.query.spoofing
    const user = await auth.doLogin(loginPayload)
    setUser(user)

    if (
      spoofingRouteQuery &&
        typeof spoofingRouteQuery === 'string' &&
        isSpoofingParamValid(spoofingRouteQuery)
    ) {
      storage.setUniversal('spoofing', spoofingRouteQuery)
    }

    await verify()
    return user
  }

  async function doLogout () {
    storage.setUniversal('actor', null)
    setUser(undefined)
    storage.removeUniversal('spoofing')
    storage.setUniversal('spoofing', null)
    storage.setUniversal('isLoggedIn', false)
    await auth.doLogout()
  }

  async function doRefetch () {
    const { $orbitApiFetch } = useNuxtApp()

    const resource = options.type.toLowerCase() as ActorType
    const url = `v2/${resource}s/${actorId.value}`

    try {
      const _actor =
        await $orbitApiFetch<Record<ActorType, Actor>>(
          url,
          {
            method: 'GET',
            params: {
              include: options.include,
              isAdminRequest: Boolean(spoofing.value)
            }
          }
        )
      if (!Object.keys(_actor).length) {
        storage.dynamicState.value.actor = null
        storage.dynamicState.value.timeZone = null
        return
      }

      storage.dynamicState.value.actor = reactive(_actor[resource])
      storage.dynamicState.value.timeZone = _actor[resource].timeZone
      return _actor[resource]
    } catch (error) {
      doHandleError(error as FetchError)
    }
  }

  async function verify () {
    if (!isLoggedIn.value) {
      return
    }

    if (!actorId.value) {
      return doLogout()
    }
    // handle invalid spoofing
    if (
      spoofing.value &&
      (
        !isValidSpoofingType(spoofee.value?.type) ||
        !isSpoofingAuthorized.value
      )
    ) {
      await clearSpoof()
    }
    // handle user with invalid roles
    if (!areUserRolesValid.value && !spoofee.value) {
      let homeUrl = user.value?.homeUrl || ''
      if (previousSpoofee.value && previousSpoofee.value.type !== 'Employee') {
        homeUrl += `/${
          previousSpoofee.value.type.toLowerCase()
        }s/${
          previousSpoofee.value.id
        }`
      }
      await navigateTo(homeUrl, { external: true })
      return
    }
    // refetch actor to validate auth status
    try {
      storage.dynamicState.value.isProcessing = true
      const actor = await doRefetch()
      if (actor) {
        storage.setUniversal('isLoggedIn', true)
      }
    } catch {
      await doLogout()
    } finally {
      storage.dynamicState.value.isProcessing = false
    }
  }

  const areUserRolesValid = computed(() => {
    if (!user.value) {
      return false
    }
    return hasRoles(options.mainRole, user.value.roles)
  })

  function setUser (user?: Nullable<User> | undefined) {
    storage.setUniversal('user', user)
    storage.setUniversal('isLoggedIn', Boolean(user))
  }

  /**
   * dynamic state
   * ==================================================================
   */
  const isLoggedIn = computed(() => {
    return Boolean(storage.dynamicState.value.isLoggedIn)
  })

  const isProcessing = computed(() => {
    return Boolean(storage.dynamicState.value.isProcessing)
  })

  const timeZone = computed(() => {
    return storage.dynamicState.value.timeZone as Nullable<string>
  })

  const user = computed(() => {
    return storage.dynamicState.value.user as Nullable<User>
  })

  return {
    actor,
    actorId,
    areUserRolesValid,
    isLoggedIn,
    isProcessing,
    isSpoofingAuthorized,
    previousSpoofee,
    spoofee,
    spoofing,
    stagedActor,
    storage,
    timeZone,
    user,
    clearSpoof,
    doLogin,
    doLogout,
    doRefetch,
    isSpoofingParamValid,
    isValidSpoofingType,
    setUser,
    spoof,
    verify
  }
}
