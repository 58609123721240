// hotjar
import Hotjar from '@hotjar/browser'

export default defineNuxtPlugin((nuxtApp) => {
  /**
 * nuxt runtime config
 * ==================================================================
 */
  const config = useRuntimeConfig()

  const _isAnalyticsEnabled = ref(config.analyticsEnable === 'true')
  const hotjarId = config.public.hotjarSiteId
  const hotjarVersion = config.public.hotjarVersion
  let isHotjarActive = false

  // Hook into the page navigation
  nuxtApp.hook('page:finish', () => {
    const route = useRoute()

    if (_isAnalyticsEnabled && route?.meta?.hasHotjarTracking) {
      if (!isHotjarActive) {
        Hotjar.init(
          Number(hotjarId),
          Number(hotjarVersion)
        )

        isHotjarActive = true
      }
    }
  })
})
