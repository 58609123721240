<script setup lang="ts" />

<template>
  <svg
    class="circle"
    :style="{ width: '68px', height: '68px' }"
    x="0"
    y="0"
    viewBox="0 0 68 68"
  >
    <g
      id="Clothing/Shirt-Crew-Neck"
      transform="translate(-24 21) scale(0.43)"
    >
      <defs>
        <path
          id="ShirtCrewNeck-path1"
          d="M165.960472,29.2949161 C202.936473,32.3249982 232,63.2942856 232,101.051724 L232,110 L32,110 L32,101.051724 C32,62.9525631 61.591985,31.7649812 99.0454063,29.2195264 C99.0152598,29.5931145 99,29.9692272 99,30.3476251 C99,42.2107177 113.998461,51.8276544 132.5,51.8276544 C151.001539,51.8276544 166,42.2107177 166,30.3476251 C166,29.9946691 165.986723,29.6437014 165.960472,29.2949161 Z"
        />
      </defs>
      <mask
        id="ShirtCrewNeck-mask1"
        fill="white"
      >
        <use href="#ShirtCrewNeck-path1" />
      </mask>
      <use
        class="CustomColor"
        fill="var(--avataaar-shirt-color)"
        href="#ShirtCrewNeck-path1"
      />
      <g
        id="Shadowy"
        opacity="0.16"
        strokeWidth="1"
        fillRule="evenodd"
        mask="url(&quot;#ShirtCrewNeck-mask1&quot;)"
        fill="#000000"
      >
        <g
          id="Hola-👋🏼"
          transform="translate(92.000000, 4.000000)"
        >
          <ellipse
            cx="40.5"
            cy="27.8476251"
            rx="39.6351047"
            ry="26.9138272"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<style scoped>
.circle {
  width: 64px;
  height: 64px;
  border-radius:  100%;
  overflow: hidden;
  margin: 0 auto;/* centers it*/
}
</style>
