<script setup lang="ts" />

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.875 6.25L16.3538 15.1101C16.2955 16.1012 15.4748 16.875 14.4821 16.875H5.51795C4.52518 16.875 3.70448 16.1012 3.64618 15.1101L3.125 6.25M8.33313 9.375H11.6665M2.8125 6.25H17.1875C17.7053 6.25 18.125 5.83027 18.125 5.3125V4.0625C18.125 3.54473 17.7053 3.125 17.1875 3.125H2.8125C2.29473 3.125 1.875 3.54473 1.875 4.0625V5.3125C1.875 5.83027 2.29473 6.25 2.8125 6.25Z"
      stroke="#5E5E5E"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
