// vue
import { computed, ref } from 'vue'

// mixpanel
import mixpanel from 'mixpanel-browser'

// types
import type { PublicRuntimeConfig } from '@nuxt/schema'
import type { Actor, Student, User } from '@revolutionprep/types'

// utils
import { doLog } from '@revolutionprep/utils'

// nuxt
import { useNuxtApp } from '#app'

export function useAnalytics (config: PublicRuntimeConfig) {
  const { $actor } = useNuxtApp()

  const _isDebug = ref(config.appDebug === 'true')
  const _mixpanelProjectToken = ref(config.mixpanelProjectToken)

  const _isAnalyticsEnabled = computed(() => {
    return (
      !$actor?.core?.spoofing.value &&
      config.analyticsEnable === 'true'
    )
  })

  function doInitializeAnalytics () {
    if (!_isAnalyticsEnabled.value) {
      if (_isDebug.value) {
        doLog(
          'Analytics doInitAnalytics :: Not Initialized',
          'info'
        )
      }

      return
    }

    if (_mixpanelProjectToken.value) {
      /**
       * initialize mixpanel
       * https://docs.mixpanel.com/docs/quickstart/install-mixpanel
       */
      mixpanel.init(_mixpanelProjectToken.value, {
        debug: _isDebug.value
      })

      if (_isDebug.value) {
        doLog(
          'Analytics doInitAnalytics :: Initialized',
          'info'
        )
      }
    }
  }

  function doIdentify (user: User, actor: Actor) {
    if (_isAnalyticsEnabled.value) {
      /**
       * call identify once you know the identity of the current user,
       * typically after log-in
       * https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#identify
       */
      mixpanel.identify(user.id)
      doSetPeopleAttributes(actor)

      if (_isDebug.value) {
        doLog(
          'Analytics doIdentify',
          'info',
          {
            user_id: user.id,
            actor_id: user.actorId,
            actor_type: user.actorType
          }
        )
      }
    }
  }

  function doRegisterSuperProperty (
    superProperties: { [key:string]: string | number | boolean | undefined }
  ) {
    if (_isAnalyticsEnabled.value) {
      /**
       * Super Properties are global Event Properties that,
       * after registration, are saved into the end user’s device,
       * and appended to every event from that device until
       * persistence is cleared
       * https://docs.mixpanel.com/docs/data-structure/property-reference/properties
       */
      mixpanel.register(superProperties)

      if (_isDebug.value) {
        doLog(
          'Analytics doRegisterSuperProperty',
          'info',
          superProperties
        )
      }
    }
  }

  function doResetAnalytics () {
    if (_isAnalyticsEnabled.value) {
      /**
       * clear data attributed to the user when they log out
       * https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#call-reset-at-logout
       */
      mixpanel.reset()

      if (_isDebug.value) {
        doLog(
          'Analytics doResetAnalytics',
          'info'
        )
      }
    }
  }

  function doSetPeopleAttributes (actor: Actor) {
    if (_isAnalyticsEnabled.value) {
      /**
       * add attributes to mixpanel user profile
       * https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#storing-user-profiles
       */
      if (actor.type === 'Student') {
        const student = actor as Student
        mixpanel.people.set({
          $actor_id: student.id,
          $actor_type: student.type,
          $employee_id: student.parent?.employee?.id,
          $grade: student.grade,
          $graduation_year: student.graduationYear,
          $parent_id: student.parent?.id,
          $revenue: student.revenue,
          $school_id: student.school?.id,
          $school_name: student.school?.name
        })
      } else {
        mixpanel.people.set({
          $actor_id: actor.id,
          $actor_type: actor.type
        })
      }
    }
  }

  function doTrack (eventName: string, properties = {}) {
    if (_isAnalyticsEnabled.value) {
      /**
       * track events with mixpanel
       * https://docs.mixpanel.com/docs/quickstart/track-events
       */
      mixpanel.track(eventName, properties)

      if (_isDebug.value) {
        doLog(
          'Analytics doTrack',
          'info',
          {
            eventName,
            properties
          }
        )
      }
    }
  }

  return {
    doIdentify,
    doInitializeAnalytics,
    doRegisterSuperProperty,
    doResetAnalytics,
    doSetPeopleAttributes,
    doTrack
  }
}
