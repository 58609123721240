<script lang="ts" setup>
</script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 17.5026V5.83594"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.3359 10.0026L15.0026 11.6693L18.3359 8.33594"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3307 5V3.33333C18.3307 3.11232 18.2429 2.90036 18.0867 2.74408C17.9304 2.5878 17.7184 2.5 17.4974 2.5H13.3307C12.4467 2.5 11.5988 2.85119 10.9737 3.47631C10.3486 4.10143 9.9974 4.94928 9.9974 5.83333C9.9974 4.94928 9.64621 4.10143 9.02109 3.47631C8.39596 2.85119 7.54812 2.5 6.66406 2.5H2.4974C2.27638 2.5 2.06442 2.5878 1.90814 2.74408C1.75186 2.90036 1.66406 3.11232 1.66406 3.33333V14.1667C1.66406 14.3877 1.75186 14.5996 1.90814 14.7559C2.06442 14.9122 2.27638 15 2.4974 15H7.4974C8.16044 15 8.79632 15.2634 9.26516 15.7322C9.734 16.2011 9.9974 16.837 9.9974 17.5C9.9974 16.837 10.2608 16.2011 10.7296 15.7322C11.1985 15.2634 11.8344 15 12.4974 15H17.4974C17.7184 15 17.9304 14.9122 18.0867 14.7559C18.2429 14.5996 18.3307 14.3877 18.3307 14.1667V13.0833"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
