// nuxt
import { useRuntimeConfig, useNuxtApp } from '#app'

// sentry
import * as Sentry from '@sentry/vue'

// types
import type { NuxtError } from '#app'
import type {
  Error as OrbitError,
  ErrorHash,
  ErrorResponse
} from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// utilities
import { errorsToSentence } from '@revolutionprep/utils'

interface ErrorData {
  errors: ErrorHash
}

export function useErrorHandler () {
  /**
   * runtime config
   * ==================================================================
   */
  const config = useRuntimeConfig()

  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor, $toast } = useNuxtApp()

  /**
   * methods
   * ==================================================================
   */
  function _displayToast (message: string) {
    if (!process.client) {
      // eslint-disable-next-line
      console.error(message)
      return
    }
    $toast.error(message)
  }

  function _doErrorMessage (status: number): string {
    switch (status) {
      case 401:
        return 'Session expired. You must login to perform this action.'
      case 403:
        return 'Forbidden. You do not have the required permissions to perform this action'
      case 404:
        return 'Not found. That record could not be found'
      case 422:
        return 'Unprocessable Entity. Your request could not be processed'
      default:
        return 'Sorry, our server has experienced an error. Please contact customer service.'
    }
  }

  function _doHandleErrorResponse (
    errorResponse: NuxtError | OrbitError | Error,
    showErrorMessage: boolean,
    customErrorMessage: string
  ): ErrorResponse {
    if (errorResponse instanceof Error) {
      const toastMessage = customErrorMessage || errorResponse.message
      if (showErrorMessage) {
        _displayToast(toastMessage)
      }
      return {
        message: errorResponse.message,
        statusCode: 500
      }
    }

    let message = ''
    let statusCode = 500

    if ('statusCode' in errorResponse && 'data' in errorResponse) {
      statusCode = Number(errorResponse?.statusCode) || 500
      message = errorResponse?.data
        ? errorsToSentence((errorResponse.data as ErrorData).errors, 'orbit')
        : _doErrorMessage(statusCode)
    }

    const toastMessage = customErrorMessage || message
    if (showErrorMessage) {
      _displayToast(toastMessage)
    }

    return {
      message,
      statusCode
    }
  }

  function doCaptureException (error: unknown) {
    if (process.client && config.public.sentryEnable) {
      Sentry.withScope((scope) => {
        scope.setTag('studentId', $actor.core.actorId.value)
        Sentry.captureException(error)
      })
    } else {
      // eslint-disable-next-line
      console.warn('⚠️ SENTRY DISABLED - ERROR: ', error)
    }
  }

  async function doHandleError (
    error: NuxtError | Error | FetchError,
    showErrorMessage = true,
    customErrorMessage = ''
  ) {
    if ('statusCode' in error && 'data' in error) {
      const status = Number(error.statusCode)
      if (![401, 404, 422].includes(status)) {
        doCaptureException(error)
      }
      const { $actor } = useNuxtApp()

      if (status === 401) {
        await $actor.core.doLogout()
        return
      }
    }
    // handle generic Error
    doCaptureException(error)

    return _doHandleErrorResponse(
      error as Error,
      showErrorMessage,
      customErrorMessage
    )
  }

  return {
    doCaptureException,
    doHandleError
  }
}
