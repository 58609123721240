<script setup lang="ts" />

<template>
  <svg
    :style="{ width: '64px', height: '64px' }"
    x="0"
    y="0"
    viewBox="0 0 72 72"
  >
    <g
      transform="translate(-10 -22) scale(0.82)"
    >
      <g
        id="Mouth/Default"
        transform="translate(2.000000, 52.000000)"
        opacity="0.699999988"
      >
        <path
          id="Mouth"
          d="M40,15 C40,22.7319865 46.2680135,29 54,29 L54,29 C61.7319865,29 68,22.7319865 68,15"
        />
      </g>
    </g>
  </svg>
</template>
