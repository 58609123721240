/**
 * Convert an array of strings into a readable sentence.
 * Optionally you can set sentence to false to not lowercase
 * items and add a dot at the end.
 *
 * @export
 * @param {string[]} items
 * @param {boolean} [sentence=true]
 * @returns {string}
 */

export default function (items: string[], sentence = true): string {
  if (!Array.isArray(items) || !items) {
    return ''
  }

  if (sentence) {
    const fragments = items.map((i, index) => {
      const item = index ? i.toLowerCase() : i
      if (item.endsWith('.')) {
        return item.slice(0, item.length - 1)
      } else {
        return item
      }
    })

    switch (items.length) {
      case 0:
        return ''
      case 1:
        return `${fragments[0]}.`
      case 2:
        return `${fragments[0]} and ${fragments[1]}.`
      default:
        return `${fragments.slice(0, -1).join(', ')}, and ${fragments[fragments.length - 1]}.`
    }
  } else {
    switch (items.length) {
      case 0:
        return ''
      case 1:
        return items[0]
      case 2:
        return `${items[0]} and ${items[1]}`
      default:
        return `${items.slice(0, -1).join(', ')}, and ${items[items.length - 1]}`
    }
  }
}
